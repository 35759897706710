<template>
  <v-container>
    <iframe
      v-if="reportFrameUrl"
      class="table-page"
      :src="reportFrameUrl"
      width="100%"
      frameborder="0"
      allowFullScreen="true"
    />
    <v-card class="table-page d-flex flex-column justify-center" v-else>
      <v-card-text class="d-flex justify-center">
        <h1 class="black-2--text">Falha ao carregar relatório</h1>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: "Panel",

    data: () => ({
      reportFrameUrl: process.env?.VUE_APP_REPORT_DESKTOP_URL || null
    })
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/stylesheets/styleguide";

  iframe {
    border-radius: 6px;
  }

  .painel {
    height: 45vh;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    h1,
    h2 {
      text-align: center;
    }
  }
</style>
